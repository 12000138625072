/*
 * @Description:
 * @Author: ZY
 * @Date: 2020-12-28 17:18:24
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-20 15:18:01
 */

export default {
  route: {
    DailyChannelSubscriptionReferral: '訂戶來源',
    DailyChannelRefundStatistics: '退款統計',
    custom: '自定義',
    'OS header': '頁面分類',
    sites: '其他站点',
    createSite: '新增站点',
    editSite: '編輯站点',
    showSite: '展示站点',
    roles: '角色',
    createRole: '新增角色',
    editRole: '編輯角色',
    directors: '導演',
    createDirector: '新增導演',
    editDirector: '編輯導演',
    showDirector: '導演細節',
    createSegpay: '新增 Segpay',
    editSegpay: '編輯 Segpay',
    segpays: 'Segpays',
    'daily-data': '每日统计',
    analytics: '分析',
    dashboard: '首页',
    banners: '橫幅',
    blocks: '版塊',
    channels: '渠道',
    comments: '評論',
    commentReports: '評論報告',
    'comment reports': '評論報告',
    cooperations: '合作',
    deals: '促銷優惠',
    informations: '信息',
    models: '女優/男優',
    orders: '訂單',
    plans: '銷售計劃',
    'sale-plans': '銷售計劃',
    posts: '新聞帖子',
    promotions: '促銷',
    questions: '題問',
    subscriptions: '訂閱',
    'ip-whitelist': 'IP 白名單',
    createWhitelist: '新增 IP',
    editWhitelist: '編輯 IP',
    supports: '客戶支持',
    tags: 'Tags',
    users: '用戶',
    advertisements: '廣告',
    videos: '視頻',
    revenue: '收入',
    DailyRevenue: '每日收入',
    // 'list-daily-revenue': 'DailyRevenue',
    DailyChannel: '每日渠道',
    DailyCampaign: '每日渠道',
    documentation: 'Documentation',
    guide: 'Guide',
    permissions: '權限',
    pagePermission: '頁面權限',
    rolePermission: '角色權限',
    directivePermission: 'Directive 權限',
    icons: '圖標',
    components: 'Components',
    tinymce: 'Tinymce',
    markdown: 'Markdown',
    jsonEditor: 'JSON 编辑器',
    splitPane: 'Splitpane',
    avatarUpload: '头像上传',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'Count To',
    componentMixin: '小组件',
    backToTop: '返回顶部',
    draggableDialog: '可拖拽对话框',
    draggableKanban: '可拖拽看板',
    draggableList: '可拖拽列表',
    draggableSelect: '可拖拽选择',
    charts: '图表',
    barChart: '柱状图表',
    lineChart: '折线图',
    mixedChart: '混合图表',
    example: '综合实例',
    nested: '路由嵌套',
    menu1: '菜单 1',
    'menu1-1': '菜单 1-1',
    'menu1-2': '菜单 1-2',
    'menu1-2-1': '菜单 1-2-1',
    'menu1-2-2': '菜单 1-2-2',
    'menu1-3': '菜单 1-3',
    menu2: '菜单 2',
    table: '表格',
    dynamicTable: '动态表格',
    draggableTable: '可拖拽表格',
    inlineEditTable: '表格内编辑',
    complexTable: '综合表格',
    tab: '分栏',
    form: '表单',
    createArticle: '新增文章',
    editArticle: '编辑文章',
    articleList: '文章列表',
    errorPages: '错误页面',
    page401: '401',
    page404: '404',
    errorLog: '错误日志',
    excel: 'Excel',
    exportExcel: '导出 Excel',
    selectExcel: '导出 已选择项',
    mergeHeader: '导出 多级表头',
    uploadExcel: '上传 Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: '导出 Zip',
    theme: '换肤',
    clipboard: '粘贴板',
    i18n: '国际化',
    externalLink: '外链',
    profile: '个人中心',
    showAdmin: '行政細節',
    editAdmin: '編輯行政',
    admins: '管理者',
    createAdmin: '新增行政',
    showAdvertisement: '廣告細節',
    editAdvertisements: '編輯廣告',
    createBanner: '新增橫幅',
    showBanner: '橫幅細節',
    editBanner: '編輯橫幅',
    createBlock: '新增版塊',
    showBlock: '版塊細節',
    editBlock: '編輯版塊',
    createChannel: '新增渠道',
    createDeal: '新增促銷優惠',
    showDeal: '促銷優惠細節',
    editDeal: '編輯促銷優惠',
    showInformation: 'Information 細節',
    editInformation: '編輯 Information',
    createModel: '新增女優/男優',
    showModel: '女優/男優細節',
    editModel: '編輯女優/男優',
    createPlan: '新增銷售計劃',
    showPlan: '銷售計劃細節',
    editPlan: '編輯銷售計劃',
    createPost: '新增新聞帖子',
    showPost: '新聞帖子細節',
    editPost: '編輯新聞帖子',
    createPromotion: '新增促銷',
    showPromotion: '促銷細節',
    editPromotion: '編輯促銷',
    createQuestion: '新增題問',
    showQuestion: '題問細節',
    editQuestion: '編輯題問',
    createTag: '新增 Tag',
    showTag: 'Tag 細節',
    editTag: '編輯 Tag',
    showUser: '用戶細節',
    editUser: '編輯用戶',
    createVideo: '新增視頻',
    batchCreateVideo: '批量新增視頻',
    showVideo: '視頻細節',
    editVideo: '編輯視頻',
    showChannel: '渠道細節',
    editChannel: '編輯渠道',
    CreateCampaign: '新增活動',
    createOrder: '新增訂單',
    editOrder: '編輯訂單',
    showSupport: '支持細節',
    showCooperation: '合作細節'
  },
  navbar: {
    logOut: '退出登录',
    dashboard: '首页',
    github: '项目地址',
    theme: '换肤',
    size: '布局大小',
    profile: '个人中心'
  },
  login: {
    title: '系统登录',
    logIn: '登录',
    username: '账号',
    password: '密码',
    any: '随便填',
    thirdparty: '第三方登录',
    thirdpartyTips: '本地不能模拟，请结合自己业务进行模拟！！！'
  },
  documentation: {
    documentation: '文档',
    github: 'Github 地址'
  },
  permission: {
    createRole: '新增角色',
    editPermission: '编辑权限',
    roles: '你的权限',
    switchRoles: '切换权限',
    tips: '在某些情况下，不适合使用 v-permission。例如：Element-UI 的 el-tab 或 el-table-column 以及其它动态渲染 dom 的场景。你只能通过手动设置 v-if 来实现。',
    delete: '删除',
    confirm: '确定',
    cancel: '取消'
  },
  guide: {
    description: '引导页对于一些第一次进入项目的人很有用，你可以简单介绍下项目的功能。本 Demo 是基于',
    button: '打开引导'
  },
  components: {
    documentation: '文档',
    tinymceTips: '富文本是管理后台一个核心的功能，但同时又是一个有很多坑的地方。在选择富文本的过程中我也走了不少的弯路，市面上常见的富文本都基本用过了，最终权衡了一下选择了Tinymce。更详细的富文本比较和介绍见',
    stickyTips: '当页面滚动到预设的位置会吸附在顶部',
    backToTopTips1: '页面滚动到指定位置会在右下角出现返回顶部按钮',
    backToTopTips2: '可自定义按钮的样式、show/hide、出现的高度、返回的位置 如需文字提示，可在外部使用Element的el-tooltip元素',
    imageUploadTips: '由于我在使用时它只有vue@1版本，而且和mockjs不兼容，所以自己改造了一下，如果大家要使用的话，优先还是使用官方版本。'
  },
  table: {
    dynamicTips1: '固定表头, 按照表头顺序排序',
    dynamicTips2: '不固定表头, 按照点击顺序排序',
    dragTips1: '默认顺序',
    dragTips2: '拖拽后顺序',
    title: '标题',
    importance: '重要性',
    type: '类型',
    remark: '点评',
    search: '搜索',
    add: '添加',
    export: '导出',
    reviewer: '审核人',
    id: '序号',
    date: '时间',
    author: '作者',
    readings: '阅读数',
    status: '状态',
    actions: '操作',
    edit: '编辑',
    publish: '发布',
    draft: '草稿',
    delete: '删除',
    cancel: '取 消',
    confirm: '确 定'
  },
  example: {
    warning: '创建和编辑页面是不能被 keep-alive 缓存的，因为keep-alive 的 include 目前不支持根据路由来缓存，所以目前都是基于 component name 来进行缓存的。如果你想类似的实现缓存效果，可以使用 localStorage 等浏览器缓存方案。或者不要使用 keep-alive 的 include，直接缓存所有页面。详情见'
  },
  errorLog: {
    tips: '请点击右上角bug小图标',
    description: '现在的管理后台基本都是spa的形式了，它增强了用户体验，但同时也会增加页面出问题的可能性，可能一个小小的疏忽就导致整个页面的死锁。好在 Vue 官网提供了一个方法来捕获处理异常，你可以在其中进行错误处理或者异常上报。',
    documentation: '文档介绍'
  },
  excel: {
    export: '导出',
    selectedExport: '导出已选择项',
    placeholder: '请输入文件名(默认excel-list)'
  },
  zip: {
    export: '导出',
    placeholder: '请输入文件名(默认file)'
  },
  pdf: {
    tips: '这里使用 window.print() 来实现下载pdf的功能'
  },
  theme: {
    change: '换肤',
    documentation: '换肤文档',
    tips: 'Tips: 它区别于 navbar 上的 theme-pick, 是两种不同的换肤方法，各自有不同的应用场景，具体请参考文档。',
    loading: '主题正在努力重置...'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    showTagsView: '显示 Tags-View',
    showSidebarLogo: '显示侧边栏 Logo',
    fixedHeader: '固定 Header',
    sidebarTextTheme: '侧边栏文字主题色'
  },
  selectOption: {
    today: '今日',
    yesterday: '昨日',
    last3days: '近3天',
    last7days: '近7天'
  },
  channel: {
    list: {
      channel: '渠道',
      visitors: '訪客',
      subscribers: '訂戶',
      revenue: '收入'
    }
  },
  analytics: {
    dailyRevenue: {
      date: '日期',
      newRevenue: '新收入',
      newMember: '新會員',
      renewRevenue: '更新收入',
      renewMember: '更新會員',
      usRenewRevenue: 'US 更新收入',
      usRenewMember: 'US 更新會員',
      refundAmount: '退款金額',
      refundRatio: '退款比率',
      refundMember: '退款會員',
      usRefundAmount: 'US 退款金額',
      usRefundMember: 'US 退款會員'
    },
    dailyCompanyData: {
      date: '日期',
      visitorsCount: '日活跃',
      newCount: '日注册',
      totalDailyRevenue: '日充值',
      totalOrderCount: '每日总订单量',
      successRate: '订单成功率',
      failRate: '订单失败率',
      pendingRate: '订单正在处理率',
      refundRate: '订单退款率',
      newRevenue: '新收入',
      renewRevenue: '更新收入',
      renewCount: '更新會員',
      refundAmount: '退款金額',
      refundCount: '退款单数'
    },
    dailyChannel: {
      date: '日期',
      channel: '渠道',
      visitors: '訪客',
      retUserCount: '回頭客戶數量',
      subscribers: '訂戶',
      revenue: '收入',
      arpu: 'ARPU',
      refundCount: '退款數量',
      refundAmount: '退款金額',
      renewCount: '更新數量',
      renewRevenue: '更新收入',
      renewArpu: '更新 ARPU'
    },
    dailyCampaign: {
      date: '日期',
      medium: '媒體',
      campaign: '識別號',
      visitors: '訪客',
      subscribers: '訂戶',
      revenue: '收入',
      arpu: 'ARPU'
    }
  },
  advertisement: {
    list: {
      position: '位置',
      name: '名稱',
      link: '鏈接',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作'
    },
    detail: {
      position: '位置',
      name: '名稱',
      image: '圖片',
      mobileImage: '移動圖像',
      link: '鏈接',
      ctaPosition: '行動呼籲位置'
    }
  },
  banner: {
    list: {
      sort: '排序',
      position: '位置',
      name: '名稱',
      type: '類型',
      link: '鏈接',
      clicks: '點擊次數',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作'
    },
    detail: {
      position: '位置',
      name: '名稱',
      title: '標題',
      subtitle: '副標題',
      type: '類型',
      cta: '行動呼籲',
      image: '圖片',
      mobileImage: '移動圖像',
      video: '視頻',
      mobileVideo: '移動視頻',
      link: '鏈接',
      sort: '排序',
      expiredAt: '過期時間'
    }
  },
  block: {
    list: {
      id: '識別號',
      title: '標題',
      type: '類型',
      page: '頁面',
      sort: '排序',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作',
      serialNumber: '序列號',
      isFree: '免費',
      status: '狀態',
      publishedAt: '發表於',
      name: '名稱',
      gender: '性別',
      isPopular: '很受歡迎'
    },
    detail: {
      title: '標題',
      description: '描述',
      type: '類型',
      page: '頁面',
      isBox: 'is box',
      sort: '排序',
      advertisement: '廣告',
      image: '圖片',
      mobileImage: '移動圖像',
      link: '鏈接',
      videos: '視頻',
      models: '女優'
    }
  },
  information: {
    list: {
      type: '類型',
      title: '標題',
      actions: '操作'
    },
    detail: {
      type: '類型',
      title: '標題',
      content: '內容'
    }
  },
  channels: {
    list: {
      id: '識別號',
      utmSource: '廣告來源',
      campaignsCount: '識別號數量',
      type: '類型',
      adminCount: '行政數量',
      profitSharing: '利潤分享',
      disabled: '禁用',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作',
      utmMedium: '廣告媒體',
      utmCampaign: '廣告名稱',
      utmTerm: '廣告關鍵詞',
      utmContent: '廣告內容',
      utmID: '廣告識別號'
    },
    detail: {
      utmSource: '廣告來源',
      campaignsCount: '識別號數量',
      type: '類型',
      utmMedium: '廣告媒體',
      utmCampaign: '廣告名稱',
      utmTerm: '廣告關鍵詞',
      utmContent: '廣告內容'
    }
  },
  comments: {
    list: {
      user: '用戶',
      video: '視頻',
      comment: '評論',
      likesCount: '點贊次數',
      status: '状态',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作',
      id: '識別號',
      reportStatus: '報告狀態'
    }
  },
  questions: {
    list: {
      sort: '排序',
      question: '題問',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作'
    },
    details: {
      question: '題問',
      answer: '答案',
      sort: '排序'
    }
  },
  support: {
    list: {
      type: '类型',
      subject: '主題',
      email: '電子郵件',
      status: '狀態',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作'
    },
    details: {
      type: '类型',
      subject: '主題',
      email: '電子郵件',
      message: '消息',
      attachment: '附件',
      status: '狀態'
    }
  },
  directors: {
    list: {
      id: '識別號',
      name: '名稱',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作'
    },
    details: {
      name: '名稱'
    }
  },
  orders: {
    list: {
      id: '識別號',
      orderID: '訂單號',
      user: '用戶',
      plan: '計劃',
      amount: '金額',
      paymentMethod: '付款方式',
      transactionNumber: '交易號',
      paidAt: '付款時間',
      transactionFee: '交易費',
      settlementFee: '結算費',
      reserveFee: '保留費',
      totalAmount: '總金額',
      createdAt: '創建於',
      updatedAt: '更新於'
    }
  },
  videos: {
    list: {
      cover: '封面',
      serialNumber: '序列號',
      title: '標題',
      originalViews: '原始瀏覽量',
      subscriberTViews: '訂戶預告片觀看次數',
      visitorTViews: '訪客預告片觀看次數',
      views: '觀看次數',
      likeDislikes: '點贊/差評',
      isFree: '免費',
      isMain: 'IS MAIN',
      status: '狀態',
      publishedAt: '發表於',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作'
    },
    details: {
      cover: '封面',
      serialNumber: '序列號',
      videoDuration: '視頻時長 (s)',
      title: '標題',
      previewVideo: '預覽視頻',
      trailer: '預告片',
      video: '視頻',
      originalViews: '原始瀏覽量',
      subscriberTViews: '訂戶預告片觀看次數',
      visitorTViews: '訪客預告片觀看次數',
      views: '觀看次數',
      tooltips: '提示',
      description: '描述',
      isFree: '免費',
      isMain: 'Is Main',
      models: '女優/男優',
      directors: '導演',
      tags: 'Tags',
      gallery: '照片庫',
      status: '狀態',
      source: '來源',
      publishedAt: '發表於'
    }
  },
  models: {
    list: {
      id: '識別號',
      name: '名稱',
      gender: '性別',
      isPopular: '很受歡迎',
      videosCount: '視頻數量',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作'
    },
    details: {
      avatar: '头像',
      name: '名稱',
      gender: '性別',
      height: '身高',
      inFeet: '英尺',
      inInch: '英寸',
      weight: '重量',
      weightLbs: '重量 (磅)',
      chest: '胸部',
      waist: '腰部',
      hips: '臀部',
      measurement: '測量',
      birthPlace: '老家',
      birthday: '生日',
      socialmedia: '社交帳號',
      links: '鏈接',
      isPopular: '很受歡迎',
      videosCount: '視頻數量',
      tooltips: '提示',
      introduction: '介紹',
      gallery: '照片庫'
    }
  },
  posts: {
    list: {
      id: '識別號',
      title: '標題',
      sticky: 'STICKY',
      status: '狀態',
      publishedAt: '發表於',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作'
    },
    details: {
      cover: '封面',
      title: '標題',
      content: '內容',
      description: '描述',
      status: '狀態',
      publishedAt: '發表於',
      isSticky: 'Is Sticky',
      models: '女優/男優'
    }
  },
  coorperations: {
    list: {
      id: '識別號',
      subject: '主題',
      name: '名稱',
      email: '電子郵件',
      phone: '電話號碼',
      status: '狀態',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作'
    },
    details: {
      id: '識別號',
      subject: '主題',
      name: '名稱',
      email: '電子郵件',
      phone: '電話號碼',
      message: '消息',
      attachment: '附件',
      status: '狀態'
    }
  },
  admins: {
    list: {
      id: '識別號',
      username: '用戶名',
      name: '名稱',
      phone: '電話號碼',
      status: '狀態',
      allocatedMembers: '分配的成員',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作'
    },
    details: {
      username: '用戶名',
      password: '密碼',
      name: '名稱',
      phone: '電話號碼',
      status: '狀態',
      roles: '角色',
      createdAt: '創建於'
    }
  },
  roles: {
    list: {
      id: '識別號',
      role: '角色',
      permissions: '权限',
      admins: '管理者',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作'
    },
    details: {
      roleName: '角色名稱',
      permissions: '权限'
    }
  },
  plans: {
    list: {
      id: '識別號',
      name: '名稱',
      title: '标题',
      initialPeriod: '初期',
      initialPrice: '初始價格',
      recurringPeriod: '定期',
      recurringPrice: '定期價格',
      subscribers: '訂戶',
      downloadable: '可下載',
      show: '展示',
      best: '最好的',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作'
    },
    details: {
      name: '名稱',
      title: '标题',
      initPeriod: '初期',
      initialPeriodCount: '初期數量',
      initialPeriodUnit: '初期單元',
      initialOrigPrice: '初期原價',
      initialDiscPrice: '初期折扣價',
      recurringPeriod: '定期',
      recurringPeriodCount: '定期數量',
      recurringPeriodUnit: '定期單元',
      recurringOrigPrice: '定期原價',
      recurringDiscPrice: '定期折扣價',
      downloadable: '可下載',
      show: '展示',
      best: '最好的',
      isBest: '是最好的',
      sort: '排序',
      segpayID: 'Segpay 識別號',
      epochID: 'Epoch 識別號',
      epochTiID: 'Epoch Ti 識別號',
      NatsID: 'Nats 識別號',
      XSaleID: 'XSale 識別號'
    }
  },
  promotions: {
    list: {
      id: '識別號',
      name: '名稱',
      code: '代碼',
      startedAt: '開始於',
      endedAt: '結束於',
      times: '次數',
      status: '状态',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作',
      initialPeriod: '初期',
      initialPrice: '初期價格',
      recurringPeriod: '定期',
      recurringPrice: '定期價格'
    },
    details: {
      name: '名稱',
      code: '代碼',
      startedAt: '開始於',
      endedAt: '結束於',
      times: '次數',
      status: '状态'
    }
  },
  tags: {
    list: {
      id: '識別號',
      name: '名稱',
      sort: '排序',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作'
    },
    details: {
      name: '名稱',
      sort: '排序'
    }
  },
  users: {
    list: {
      id: '識別號',
      username: '用戶名',
      email: '電子郵件',
      status: '状态',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作'
    },
    details: {
      avatar: '头像',
      username: '用戶名',
      email: '電子郵件',
      firstName: '名',
      lastName: '姓',
      birthdate: '生日',
      phone: '電話號碼',
      status: '状态',
      plan: '計劃',
      startDate: '開始於',
      endDate: '結束於',
      banner: '橫幅'
    }
  },

  common: {
    list: {
      id: '識別號',
      name: '名稱',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作'
    },
    details: {
      id: '識別號',
      name: '名稱',
      createdAt: '創建於',
      updatedAt: '更新於',
      actions: '操作'
    }
  }
};
