/*
 * @Description:
 * @Author: ZY
 * @Date: 2020-12-28 17:18:17
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-20 15:18:42
 */

export default {
  route: {
    DailyChannelSubscriptionReferral: 'Subscriber Sources',
    DailyChannelRefundStatistics: 'Refund Statistics',
    custom: 'Custom',
    'OS header': 'OS Header',
    sites: 'Sites',
    createSite: 'Create Site',
    editSite: 'Edit Site',
    showSite: 'Show Site',
    roles: 'Roles',
    createRole: 'Create Role',
    editRole: 'Edit Role',
    directors: 'Directors',
    createDirector: 'Create Director',
    editDirector: 'Edit Director',
    showDirector: 'Director Detail',
    createSegpay: 'Create Segpay',
    editSegpay: 'Edit Segpay',
    segpays: 'Segpays',
    'daily-data': 'Daily Data',
    analytics: 'Analytics',
    dashboard: 'Dashboard',
    banners: 'Banners',
    blocks: 'Blocks',
    channels: 'Channels',
    comments: 'Comments',
    commentReports: 'Comment Report',
    'comment reports': 'Comment Reports',
    cooperations: 'Cooperations',
    deals: 'Deals',
    informations: 'Information',
    models: 'Models',
    orders: 'Orders',
    plans: 'Plans',
    'sale-plans': 'Sale Plans',
    posts: 'Posts',
    promotions: 'Promotions',
    questions: 'Questions',
    subscriptions: 'Subscriptions',
    'ip-whitelist': 'IP Whitelist',
    createWhitelist: 'Add new IP',
    editWhitelist: 'Edit IP',
    supports: 'Supports',
    tags: 'Tags',
    users: 'Users',
    advertisements: 'Advertisements',
    videos: 'Videos',
    revenue: 'Revenue',
    DailyRevenue: 'Daily Revenue',
    // 'list-daily-revenue': 'DailyRevenue',
    DailyChannel: 'Daily Channel',
    DailyCampaign: 'Daily Campaign',
    documentation: 'Documentation',
    guide: 'Guide',
    permissions: 'Permissions',
    pagePermission: 'Page Permission',
    rolePermission: 'Role Permission',
    directivePermission: 'Directive Permission',
    icons: 'Icons',
    components: 'Components',
    tinymce: 'Tinymce',
    markdown: 'Markdown',
    jsonEditor: 'JSON Editor',
    splitPane: 'SplitPane',
    avatarUpload: 'Avatar Upload',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'Count To',
    componentMixin: 'Mixin',
    backToTop: 'Back To Top',
    draggableDialog: 'Draggable Dialog',
    draggableKanban: 'Draggable Kanban',
    draggableList: 'Draggable List',
    draggableSelect: 'Draggable Select',
    charts: 'Charts',
    barChart: 'Bar Chart',
    lineChart: 'Line Chart',
    mixedChart: 'Mixed Chart',
    example: 'Example',
    nested: 'Nested Routes',
    menu1: 'Menu 1',
    'menu1-1': 'Menu 1-1',
    'menu1-2': 'Menu 1-2',
    'menu1-2-1': 'Menu 1-2-1',
    'menu1-2-2': 'Menu 1-2-2',
    'menu1-3': 'Menu 1-3',
    menu2: 'Menu 2',
    table: 'Table',
    dynamicTable: 'Dynamic Table',
    draggableTable: 'Draggable Table',
    inlineEditTable: 'Inline Edit Table',
    complexTable: 'Complex Table',
    tab: 'Tab',
    form: 'Form',
    createArticle: 'Create Article',
    editArticle: 'Edit Article',
    articleList: 'Article List',
    errorPages: 'Error Pages',
    page401: '401',
    page404: '404',
    errorLog: 'Error Log',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    selectExcel: 'Export Selected',
    mergeHeader: 'Merge Header',
    uploadExcel: 'Upload Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Export Zip',
    theme: 'Theme',
    clipboard: 'Clipboard',
    i18n: 'I18n',
    externalLink: 'External Link',
    profile: 'Profile',
    showAdmin: 'Admin Detail',
    editAdmin: 'Edit Admin',
    admins: 'Admins',
    createAdmin: 'Create Admin',
    showAdvertisement: 'Advertisement Detail',
    editAdvertisements: 'Edit Advertisement',
    createBanner: 'Create Banner',
    showBanner: 'Banner Detail',
    editBanner: 'Edit Banner',
    createBlock: 'Create Block',
    showBlock: 'Block Detail',
    editBlock: 'Edit Block',
    createChannel: 'Create Channel',
    createDeal: 'Create Deal',
    showDeal: 'Deal Detail',
    editDeal: 'Edit Deal',
    showInformation: 'Information Detail',
    editInformation: 'Edit Information',
    createModel: 'Create Model',
    showModel: 'Model Detail',
    editModel: 'Edit Model',
    createPlan: 'Create Plan',
    showPlan: 'Plan Detail',
    editPlan: 'Edit Plan',
    createPost: 'Create Post',
    showPost: 'Post Detail',
    editPost: 'Edit Post',
    createPromotion: 'Create Promotion',
    showPromotion: 'Promotion Detail',
    editPromotion: 'Edit Promotion',
    createQuestion: 'Create Question',
    showQuestion: 'Question Detail',
    editQuestion: 'Edit Question',
    createTag: 'Create Tag',
    showTag: 'Tag Detail',
    editTag: 'Edit Tag',
    showUser: 'User Detail',
    editUser: 'Edit User',
    createVideo: 'Create Video',
    batchCreateVideo: 'Batch Create Video',
    showVideo: 'Video Detail',
    editVideo: 'Edit Video',
    showChannel: 'Channel Detail',
    editChannel: 'Edit Channel',
    CreateCampaign: 'Create Campaign',
    createOrder: 'Create Order',
    editOrder: 'Edit Order',
    showSupport: 'Support Detail',
    showCooperation: 'Cooperation Detail'
  },
  navbar: {
    logOut: 'Log Out',
    dashboard: 'Dashboard',
    github: 'Github',
    theme: 'Theme',
    size: 'Global Size',
    profile: 'Profile'
  },
  login: {
    title: 'Login Form',
    logIn: 'Login',
    username: 'Username',
    password: 'Password',
    any: 'any',
    thirdparty: 'Or connect with',
    thirdpartyTips:
      'Can not be simulated on local, so please combine you own business simulation! ! !'
  },
  documentation: {
    documentation: 'Documentation',
    github: 'Github Repository'
  },
  permission: {
    createRole: 'New Role',
    editPermission: 'Edit',
    roles: 'Your roles',
    switchRoles: 'Switch roles',
    tips: 'In some cases, using v-permission will have no effect. For example: Element-UI  el-tab or el-table-column and other scenes that dynamically render dom. You can only do this with v-if.',
    delete: 'Delete',
    confirm: 'Confirm',
    cancel: 'Cancel'
  },
  guide: {
    description:
      'The guide page is useful for some people who entered the project for the first time. You can briefly introduce the features of the project. Demo is based on ',
    button: 'Show Guide'
  },
  components: {
    documentation: 'Documentation',
    tinymceTips:
      'Rich text is a core feature of the management backend, but at the same time it is a place with lots of pits. In the process of selecting rich texts, I also took a lot of detours. The common rich texts on the market have been basically used, and I finally chose Tinymce. See the more detailed rich text comparison and introduction.',
    stickyTips:
      'when the page is scrolled to the preset position will be sticky on the top.',
    backToTopTips1:
      'When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner',
    backToTopTips2:
      'You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally',
    imageUploadTips:
      'Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version.'
  },
  table: {
    dynamicTips1: 'Fixed header, sorted by header order',
    dynamicTips2: 'Not fixed header, sorted by click order',
    dragTips1: 'The default order',
    dragTips2: 'The after dragging order',
    title: 'Title',
    importance: 'Importance',
    type: 'Type',
    remark: 'Remark',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    reviewer: 'Reviewer',
    id: 'ID',
    date: 'Date',
    author: 'Author',
    readings: 'Readings',
    status: 'Status',
    actions: 'Actions',
    edit: 'Edit',
    publish: 'Publish',
    draft: 'Draft',
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm'
  },
  example: {
    warning:
      "Creating and editing pages cannot be cached by keep-alive because keep-alive's 'include' currently does not support caching based on routes, so now it's cached based on component name. If you want to achieve a similar caching effect, you can use a browser caching scheme such as localStorage. Or don't use keep-alive's 'include' to cache all pages directly. See details"
  },
  errorLog: {
    tips: 'Please click the bug icon in the upper right corner',
    description:
      'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction'
  },
  excel: {
    export: 'Export',
    selectedExport: 'Export Selected Items',
    placeholder: 'Please enter the file name (default excel-list)'
  },
  zip: {
    export: 'Export',
    placeholder: 'Please enter the file name (default file)'
  },
  pdf: {
    tips: 'Here we use window.print() to implement the feature of downloading PDF.'
  },
  theme: {
    change: 'Change Theme',
    documentation: 'Theme documentation',
    tips: 'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.',
    loading: 'Theme change loading...'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    showTagsView: 'Open Tags-View',
    showSidebarLogo: 'Sidebar Logo',
    fixedHeader: 'Fixed Header',
    sidebarTextTheme: 'Sidebar Text Theme'
  },
  selectOption: {
    today: 'Today',
    yesterday: 'Yesterday',
    last3days: 'Last 3 days',
    last7days: 'Last 7 days'
  },
  channel: {
    list: {
      channel: 'CHANNEL',
      visitors: 'VISITORS',
      subscribers: 'SUBSCRIBERS',
      revenue: 'REVENUE'
    }
  },
  analytics: {
    dailyRevenue: {
      date: 'DATE',
      newRevenue: 'NEW REVENUE',
      newMember: 'NEW MEMBER',
      renewRevenue: 'RENEW REVENUE',
      renewMember: 'RENEW MEMBER',
      usRenewRevenue: 'US RENEW REVENUE',
      usRenewMember: 'US RENEW MEMBER',
      refundAmount: 'REFUND AMOUNT',
      refundRatio: 'REFUND RATIO',
      refundMember: 'REFUND MEMBER',
      usRefundAmount: 'US REFUND AMOUNT',
      usRefundMember: 'US REFUND MEMBER'
    },
    dailyCompanyData: {
      date: 'DATE',
      visitorsCount: 'VISITORS COUNT',
      newCount: 'NEW MEMBER',
      totalDailyRevenue: 'TOTAL DAILY REVENUE',
      totalOrderCount: 'TOTAL ORDER COUNT',
      successRate: 'SUCCESS RATE',
      failRate: 'FAIL RATE',
      pendingRate: 'PENDING RATE',
      refundRate: 'REFUND RATE',
      newRevenue: 'NEW REVENUE',
      renewRevenue: 'RENEW REVENUE',
      renewCount: 'RENEW COUNT',
      refundAmount: 'REFUND AMOUNT',
      refundCount: 'REFUND COUNT'
    },
    dailyChannel: {
      date: 'DATE',
      channel: 'CHANNEL',
      visitors: 'VISITORS',
      retUserCount: 'RETURNING USER COUNT',
      subscribers: 'SUBSCRIBERS',
      revenue: 'REVENUE',
      arpu: 'ARPU',
      refundCount: 'REFUND COUNT',
      refundAmount: 'REFUND AMOUNT',
      renewCount: 'RENEW COUNT',
      renewRevenue: 'RENEW REVENUE',
      renewArpu: 'RENEW ARPU'
    },
    dailyCampaign: {
      date: 'DATE',
      medium: 'MEDIUM',
      campaign: 'CAMPAIGN',
      visitors: 'VISITORS',
      subscribers: 'SUBSCRIBERS',
      revenue: 'REVENUE',
      arpu: 'ARPU'
    }
  },
  advertisement: {
    list: {
      position: 'POSITION',
      name: 'NAME',
      link: 'LINK',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS'
    },
    detail: {
      position: 'Position',
      name: 'Name',
      image: 'Image',
      mobileImage: 'Mobile Image',
      link: 'Link',
      ctaPosition: 'CTA Position'
    }
  },
  banner: {
    list: {
      sort: 'SORT',
      position: 'POSITION',
      name: 'NAME',
      type: 'TYPE',
      link: 'LINK',
      clicks: 'CLICKS',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS'
    },
    detail: {
      position: 'Position',
      name: 'Name',
      title: 'Title',
      subtitle: 'Subtitle',
      type: 'Type',
      cta: 'CTA',
      image: 'Image',
      mobileImage: 'Mobile Image',
      video: 'Video',
      mobileVideo: 'Mobile Video',
      link: 'Link',
      sort: 'Sort',
      expiredAt: 'Expired At'
    }
  },
  block: {
    list: {
      id: 'ID',
      title: 'TITLE',
      type: 'TYPE',
      page: 'PAGE',
      sort: 'SORT',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS',
      serialNumber: 'SERIAL NUMBER',
      isFree: 'IS FREE',
      status: 'STATUS',
      publishedAt: 'PUBLISHED AT',
      name: 'NAME',
      gender: 'GENDER',
      isPopular: 'IS POPULAR'
    },
    detail: {
      title: 'Title',
      description: 'Description',
      type: 'Type',
      page: 'Page',
      isBox: 'Is Box',
      sort: 'Sort',
      advertisement: 'Advertisement',
      image: 'Image',
      mobileImage: 'Mobile Image',
      link: 'Link',
      videos: 'Videos',
      models: 'Models'
    }
  },
  information: {
    list: {
      type: 'TYPE',
      title: 'TITLE',
      actions: 'ACTIONS'
    },
    detail: {
      type: 'Type',
      title: 'Title',
      content: 'Content'
    }
  },
  channels: {
    list: {
      id: 'ID',
      utmSource: 'UTM SOURCE',
      campaignsCount: 'CAMPAIGNS COUNT',
      type: 'TYPE',
      adminCount: 'ADMIN COUNT',
      profitSharing: 'PROFIT SHARING',
      disabled: 'DISABLED',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS',
      utmMedium: 'UTM MEDIUM',
      utmCampaign: 'UTM CAMPAIGN',
      utmTerm: 'UTM TERM',
      utmContent: 'UTM CONTENT',
      utmID: 'UTM ID'
    },
    detail: {
      utmSource: 'UTM Source',
      campaignsCount: 'Campaigns Count',
      type: 'Type',
      utmMedium: 'UTM Medium',
      utmCampaign: 'UTM Campaign',
      utmTerm: 'UTM Term',
      utmContent: 'UTM Content'
    }
  },
  comments: {
    list: {
      user: 'USER',
      video: 'VIDEO',
      comment: 'COMMENT',
      likesCount: 'LIKES COUNT',
      status: 'STATUS',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS',
      id: 'ID',
      reportStatus: 'REPORT STATUS'
    }
  },
  questions: {
    list: {
      sort: 'SORT',
      question: 'QUESTION',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS'
    },
    details: {
      question: 'Question',
      answer: 'Answer',
      sort: 'Sort'
    }
  },
  support: {
    list: {
      type: 'TYPE',
      subject: 'SUBJECT',
      email: 'EMAIL',
      status: 'STATUS',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS'
    },
    details: {
      type: 'Type',
      subject: 'Subject',
      email: 'Email',
      message: 'Message',
      attachment: 'Attachment',
      status: 'Status'
    }
  },
  directors: {
    list: {
      id: 'ID',
      name: 'NAME',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS'
    },
    details: {
      name: 'Name'
    }
  },
  orders: {
    list: {
      id: 'ID',
      orderID: 'ORDER ID',
      user: 'USER',
      plan: 'PLAN',
      amount: 'AMOUNT',
      paymentMethod: 'PAYMENT METHOD',
      transactionNumber: 'TRANSACTION NUMBER',
      paidAt: 'PAID AT',
      transactionFee: 'TRANSACTION FEE',
      settlementFee: 'SETTLEMENT FEE',
      reserveFee: 'RESERVE FEE',
      totalAmount: 'TOTAL AMOUNT',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT'
    }
  },
  videos: {
    list: {
      cover: 'COVER',
      serialNumber: 'SERIAL NUMBER',
      title: 'TITLE',
      originalViews: 'ORIGINAL VIEWS',
      subscriberTViews: 'SUBSCRIBER TRAILER VIEWS',
      visitorTViews: 'VISITOR TRAILER VIEWS',
      views: 'VIEWS',
      likeDislikes: 'LIKE/DISLIKE',
      isFree: 'IS FREE',
      isMain: 'IS MAIN',
      status: 'STATUS',
      publishedAt: 'PUBLISHED AT',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS'
    },
    details: {
      cover: 'Cover',
      serialNumber: 'Serial Number',
      videoDuration: 'Video Duration (s)',
      title: 'Title',
      previewVideo: 'Preview Video',
      trailer: 'Trailer',
      video: 'Video',
      originalViews: 'Original Views',
      subscriberTViews: 'Subscriber Trailer Views',
      visitorTViews: 'Visitor Trailer Views',
      views: 'Views',
      tooltips: 'Tooltips',
      description: 'Description',
      isFree: 'Is Free',
      isMain: 'Is Main',
      models: 'Models',
      directors: 'Directors',
      tags: 'Tags',
      gallery: 'Gallery',
      status: 'Status',
      source: 'Source',
      publishedAt: 'Published At'
    }
  },
  models: {
    list: {
      id: 'ID',
      name: 'NAME',
      gender: 'GENDER',
      isPopular: 'IS POPULAR',
      videosCount: 'VIDEOS COUNT',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS'
    },
    details: {
      avatar: 'Avatar',
      name: 'Name',
      gender: 'Gender',
      height: 'Height',
      inFeet: 'in feet',
      inInch: 'in inch',
      weight: 'Weight',
      weightLbs: 'Weight (lbs)',
      chest: 'Chest',
      waist: 'Waist',
      hips: 'Hips',
      measurement: 'Measurement',
      birthPlace: 'Birth Place',
      birthday: 'Birthday',
      socialmedia: 'Social Media',
      links: 'Links',
      isPopular: 'Is Popular',
      videosCount: 'Videos Count',
      tooltips: 'Tooltips',
      introduction: 'Introduction',
      gallery: 'Gallery'
    }
  },
  posts: {
    list: {
      id: 'ID',
      title: 'TITLE',
      sticky: 'STICKY',
      status: 'STATUS',
      publishedAt: 'PUBLISHED AT',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS'
    },
    details: {
      cover: 'Cover',
      title: 'Title',
      content: 'Content',
      description: 'Description',
      status: 'Status',
      publishedAt: 'Published At',
      isSticky: 'Is Sticky',
      models: 'Models'
    }
  },
  coorperations: {
    list: {
      id: 'ID',
      subject: 'SUBJECT',
      name: 'NAME',
      email: 'EMAIL',
      phone: 'PHONE',
      status: 'STATUS',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS'
    },
    details: {
      id: 'ID',
      subject: 'Subject',
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      message: 'Message',
      attachment: 'Attachment',
      status: 'Status'
    }
  },
  admins: {
    list: {
      id: 'ID',
      username: 'USERNAME',
      name: 'NAME',
      phone: 'PHONE',
      status: 'STATUS',
      allocatedMembers: 'ALLOCATED MEMBERS',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS'
    },
    details: {
      username: 'Username',
      password: 'Password',
      name: 'Name',
      phone: 'Phone',
      status: 'Status',
      roles: 'Roles',
      createdAt: 'Created At'
    }
  },
  roles: {
    list: {
      id: 'ID',
      role: 'ROLE',
      permissions: 'PERMISSIONS',
      admins: 'ADMINS',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS'
    },
    details: {
      roleName: 'Role Name',
      permissions: 'Permissions'
    }
  },
  plans: {
    list: {
      id: 'ID',
      name: 'NAME',
      title: 'TITLE',
      initialPeriod: 'INITIAL PERIOD',
      initialPrice: 'INITIAL PRICE',
      recurringPeriod: 'RECURRING PERIOD',
      recurringPrice: 'RECURRING PRICE',
      subscribers: 'SUBSCRIBERS',
      downloadable: 'DOWNLOADABLE',
      show: 'SHOW',
      best: 'BEST',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS'
    },
    details: {
      name: 'Name',
      title: 'Title',
      initPeriod: 'Initial Period',
      initialPeriodCount: 'Initial Period Count',
      initialPeriodUnit: 'Initial Period Unit',
      initialOrigPrice: 'Initial Original Price',
      initialDiscPrice: 'Initial Discount Price',
      recurringPeriod: 'Recurring Period',
      recurringPeriodCount: 'Recurring Period Count',
      recurringPeriodUnit: 'Recurring Period Unit',
      recurringOrigPrice: 'Recurring Original Price',
      recurringDiscPrice: 'Recurring Discount Price',
      downloadable: 'Downloadable',
      show: 'Show',
      best: 'Best',
      isBest: 'Is Best',
      sort: 'Sort',
      segpayID: 'Segpay ID',
      epochID: 'Epoch ID',
      epochTiID: 'Epoch Ti ID',
      NatsID: 'Nats ID',
      XSaleID: 'XSale ID'
    }
  },
  promotions: {
    list: {
      id: 'ID',
      name: 'NAME',
      code: 'CODE',
      startedAt: 'STARTED AT',
      endedAt: 'ENDED AT',
      times: 'TIMES',
      status: 'STATUS',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS',
      initialPeriod: 'INITIAL PERIOD',
      initialPrice: 'INITIAL PRICE',
      recurringPeriod: 'RECURRING PERIOD',
      recurringPrice: 'RECURRING PRICE'
    },
    details: {
      name: 'Name',
      code: 'Code',
      startedAt: 'Started At',
      endedAt: 'Ended At',
      times: 'Times',
      status: 'Status'
    }
  },
  tags: {
    list: {
      id: 'ID',
      name: 'NAME',
      sort: 'SORT',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS'
    },
    details: {
      name: 'Name',
      sort: 'Sort'
    }
  },
  users: {
    list: {
      id: 'ID',
      username: 'Username',
      email: 'EMAIL',
      status: 'Status',
      createdAt: 'CREATED AT',
      updatedAt: 'UPDATED AT',
      actions: 'ACTIONS'
    },
    details: {
      avatar: 'Avatar',
      username: 'Username',
      email: 'Email',
      firstName: 'First Name',
      lastName: 'Last Name',
      birthdate: 'Birthdate',
      phone: 'Phone',
      status: 'Status',
      plan: 'Plan',
      startDate: 'Start Date',
      endDate: 'End Date',
      banner: 'Banner'
    }
  }
};
