import { request, requestFile } from './utils';
import { saveAs } from 'file-saver';

import {
  DashboardChannelListResponse,
  DashboardDevicesResponse,
  DashboardMemberTrendResponse,
  DashboardPlanListResponse,
  DashboardResponse,
  DashboardRevenueGatherResponse,
  DashboardRevenueTrendResponse,
  GetDashboardRequestOptions,
  DashboardRealTimeUsersResponse,
  DashboardRealTimeRevenuesResponse
} from './interfaces';

export function getDashboard({ query }: GetDashboardRequestOptions = {}): Promise<DashboardResponse> {
  return request({
    url: '/dashboard',
    method: 'get',
    params: query
  });
}

export function getDashboardRealTimeUsers({ query }: GetDashboardRequestOptions = {}): Promise<DashboardRealTimeUsersResponse> {
  return request({
    url: '/dashboard/real-time-users',
    method: 'get',
    params: query
  });
}

export function getDashboardRealTimeRevenues({ query }: GetDashboardRequestOptions = {}): Promise<DashboardRealTimeRevenuesResponse> {
  return request({
    url: '/dashboard/real-time-revenues',
    method: 'get',
    params: query
  });
}

export function getDashboardRevenueGather({ query }: GetDashboardRequestOptions = {}): Promise<DashboardRevenueGatherResponse> {
  return request({
    url: '/dashboard/revenueGather',
    method: 'get',
    params: query
  });
}
export function getDashboardRevenueTrend({ query }: GetDashboardRequestOptions = {}): Promise<DashboardRevenueTrendResponse> {
  return request({
    url: '/dashboard/revenueTrend',
    method: 'get',
    params: query
  });
}

export function getDashboardMemberTrend({ query }: GetDashboardRequestOptions = {}): Promise<DashboardMemberTrendResponse> {
  return request({
    url: '/dashboard/memberTrend',
    method: 'get',
    params: query
  });
}

export function getDashboardChannelList({ query }: GetDashboardRequestOptions = {}): Promise<DashboardChannelListResponse> {
  return request({
    url: '/dashboard/channelList',
    method: 'get',
    params: query
  });
}

export function getDashboardPlanList({ query }: GetDashboardRequestOptions = {}): Promise<DashboardPlanListResponse> {
  return request({
    url: '/dashboard/planList',
    method: 'get',
    params: query
  });
}

export function getDashboardDevices({ query }: GetDashboardRequestOptions = {}): Promise<DashboardDevicesResponse> {
  return request({
    url: '/dashboard/devices',
    method: 'get',
    params: query
  });
}

export async function triggerExportDashboardCsv() {
  console.log('inside triggerExportDashboardCsv');

  try {
    const response = await requestFile.get('/dashboard/total-company-data-exportCsv');
    console.log('response blob 1', response);
    console.log('response blob 2', response.data);
    console.log('response.status:', response.status);

    if (response.status === 200) {
      const blob = new Blob([response.data], { type: 'text/csv' });
      console.log('response blob 3', blob);

      saveAs(blob, 'total.csv');
      console.log('successful download of CSV blob 3');
    } else {
      console.error('Failed to download CSV');
    }
    return true; // Successful export

  } catch (error) {
    console.error('CSV download failed:', error);
    return false; // Failed export
  }
}
